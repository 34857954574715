.container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: bottom;
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
    background-image: linear-gradient(rgba(255,255,255,.9) 60%, transparent 100%);
}